import { createContext, useMemo, useState } from "react";
import moment from "moment";
import "moment/locale/es";
import {
  initialMonthState,
  initialSDKState,
  initialState,
} from "../states/contexts/DatesContext";
import { DATE_TYPE } from "../constants";

const DatesContext = createContext();

const DatesProvider = ({ children }) => {
  const [dates, setDates] = useState(initialState);
  const [rulesDates, setRulesDates] = useState(initialState);
  const [sdkDates, setSdkDates] = useState(initialSDKState);
  const [billingDates, setBillingDates] = useState(initialMonthState);
  const [csvDates, setCsvDates] = useState(initialMonthState);
  const [updateChart, setUpdateChart] = useState(true);
  const [updateSDKChart, setUpdateSDKChart] = useState(true);
  const [updateRules, setUpdateRules] = useState(true);

  const resetDates = () => {
    setDates(initialState);
    setUpdateChart(true);
  };

  const createDateObject = (date) => ({
    year: Number(date.format("YYYY")),
    month: date.format("MMMM"),
    monthNumber: date.month(),
    day: Number(date.format("D")),
  });

  const resetBillingDates = () => setBillingDates(initialState);

  const handleDates = ({
    startDate,
    endDate,
    single,
    dateType = DATE_TYPE.CONSULTAS,
  }) => {
    const today = moment();

    if (single) {
      setDates({ ...single });
      return;
    }

    let from, to;

    if (endDate.isAfter(today)) {
      from = createDateObject(startDate);
      to = createDateObject(today);
    } else {
      from = createDateObject(startDate);
      to = createDateObject(endDate);
    }

    switch (dateType) {
      case DATE_TYPE.CONSULTAS:
        setDates({ from, to });
        break;

      case DATE_TYPE.RULES:
        setRulesDates({ from, to });
        break;

      case DATE_TYPE.SDK:
        setSdkDates({ from, to });
        break;

      default:
        setDates(initialState);
        setRulesDates(initialState);
        setSdkDates(initialState);
        break;
    }
  };

  const handleBillingDates = ({ startDate, endDate, single }) => {
    const today = moment();
    const oneMonthAgo = moment().subtract(1, "months");

    const adjustDate = (date, limitInThePast) => {
      if (date.isBefore(limitInThePast)) return limitInThePast.startOf("month");
      if (date.isAfter(today)) return today;
      return date;
    };

    let from, to;

    if (single) {
      from = adjustDate(
        moment({
          year: single.from.year,
          month: single.from.monthNumber,
          day: single.from.day,
        }),
        oneMonthAgo
      );
      to = adjustDate(
        moment({
          year: single.to.year,
          month: single.to.monthNumber,
          day: single.to.day,
        }),
        oneMonthAgo
      );
    } else {
      from = adjustDate(moment(startDate), oneMonthAgo);
      to = adjustDate(moment(endDate), oneMonthAgo);
    }

    setBillingDates({ from: createDateObject(from), to: createDateObject(to) });
  };

  const handleCSVDates = ({ startDate, endDate, single }) => {
    const start = moment(startDate);
    const threeMonthsBeforeStart = moment(start).subtract(3, "months");
    const threeMonthsAfterStart = moment(start).add(3, "months");
  
    const adjustDate = (date) => {
      if (date.isBefore(threeMonthsBeforeStart)) return threeMonthsBeforeStart;
      if (date.isAfter(threeMonthsAfterStart)) return threeMonthsAfterStart;
      return date;
    };
    let from, to;
    if (single) {
      from = adjustDate(
        moment({
          year: single.from.year,
          month: single.from.monthNumber,
          day: single.from.day,
        })
      );
      to = adjustDate(
        moment({
          year: single.to.year,
          month: single.to.monthNumber,
          day: single.to.day,
        })
      );
    } else {
      from = adjustDate(moment(startDate));
      to = adjustDate(moment(endDate));
    }
    setCsvDates({ from: createDateObject(from), to: createDateObject(to) });
  };

  const dataToShare = useMemo(
    () => ({
      dates,
      updateChart,
      billingDates,
      resetDates,
      resetBillingDates,
      setDates,
      handleDates,
      setUpdateChart,
      handleBillingDates,
      csvDates,
      handleCSVDates,
      rulesDates,
      updateRules,
      setUpdateRules,
      setSdkDates,
      sdkDates,
      setRulesDates,
      updateSDKChart,
      setUpdateSDKChart,
    }),
    [
      dates,
      updateChart,
      billingDates,
      resetDates,
      resetBillingDates,
      setDates,
      handleDates,
      setUpdateChart,
      handleBillingDates,
      csvDates,
      handleCSVDates,
      rulesDates,
      updateRules,
      setUpdateRules,
      setSdkDates,
      sdkDates,
      setRulesDates,
      updateSDKChart,
      setUpdateSDKChart,
    ]
  );

  return (
    <DatesContext.Provider value={dataToShare}>
      {children}
    </DatesContext.Provider>
  );
};

export { DatesProvider };
export default DatesContext;
